import * as React from 'react'

const OurBrandsBanner = ({ title, description }) => {
    return(
        <section className='in-view our-brands__banner'>
            <div className="our-brands__banner-content text-center slide-up d-flex justify-content-center align-items-center pt-5">
                <div className="py-0 pt-lg-5 mt-5 w-100">
                    {(title)?
                        <h2><span className='px-5'>{title}</span></h2>
                    : null}
                    {(description)?
                        <div className='mx-auto desc-wrapper mt-lg-5 pt-3' dangerouslySetInnerHTML={{ __html: description }}/>
                    : null}
                </div>
            </div>
        </section>
    )
}

export default OurBrandsBanner