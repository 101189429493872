import React, {useState, useEffect} from 'react'
import { graphql } from 'gatsby'
import { SliceZone } from '@prismicio/react'

import Layout from '../components/Layout'
import { Seo } from '../components/Seo'
import { components } from '../slices/our-brands'

//components
import OurBrandsBanner from '../components/OurBrandsBanner'

//preview
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const OurBrandsPage = ({ data }) => {
    /* REFORMAT DATA FOR SLICEZONE DATA */
    const pageData = data.prismicOurBrandsPage.data
    const [sliceData, setSliceData] = useState([])

    useEffect(() => {
        /* ADD MODULES AND SECTIONS TO SLICEDATA */
        const newSlideData = []

        // Our Brands Module
        var moduleOurBrand = data.prismicOurBrandsSection.data
        var ourBrandSliceData = {
            slice_type: "our_brands_section",
            primary: {
            section_title: moduleOurBrand.section_title,
            section_desc: moduleOurBrand.section_text_field,
            brand_link_image_text_group: moduleOurBrand.brand_link_image_text_group
            }
        }
        var checkOurBrandDuplicateData = newSlideData.map((item) => {
          if(item.slice_type === "our_brands_section") return true;
          return false;
        })
        if(!checkOurBrandDuplicateData.includes(true))  newSlideData.push(ourBrandSliceData)

        setSliceData(newSlideData)

        //Scroll to the top on new page
        document.documentElement.style.scrollBehavior = 'auto'
        window.scrollTo(0,0)

    }, [data.prismicOurBrandsSection.data])

    return(
        <Layout currentPage="our-brands" customPage={true}>
            <Seo
                title={(pageData.meta_title)? pageData.meta_title.text : null}
                description={(pageData.meta_description)? pageData.meta_description.text : null}
            />
            <OurBrandsBanner
              title={pageData.banner_title.text}
              description={pageData.banner_description.html}
            />
            <SliceZone slices={sliceData} components={components} />
        </Layout>
    )
}

export default withPrismicPreview(OurBrandsPage)

export const query = graphql`
    query OurBrandsPageQuery {
        prismicOurBrandsPage {
          _previewable
            data {
              banner_description {
                html
                text
              }
              banner_title {
                text
              }
              meta_description {
                text
              }
              meta_title {
                text
              }
            }
        }
        prismicOurBrandsSection{
          _previewable
            data {
              section_title {
                text
              }
              section_text_field {
                text
              }
              brand_link_image_text_group {
                brand_image {
                  alt
                  copyright
                  url
                }
                brand_link {
                  size
                  slug
                  tags
                  target
                  type
                  uid
                  url
                  link_type
                  lang
                  isBroken
                  id
                }
                brand_name {
                  richText
                  text
                  html
                }
              }
            }
        }
    }
`